import { createApp } from 'vue'
import App from '@/plugins/app/_root/App.vue'
import router from '@/plugins/app/_router';

import { IonicVue } from '@ionic/vue';

import axios from '@/plugins/w/axios'
import ionic from '@/plugins/w/ionic'
import wBugger from '@/plugins/w/bugger'
import ionModals from './plugins/w/ion-modals'
import ionPopups from './plugins/w/ion-popups'
import ionicons from '@/plugins/w/ionicons/index'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
// import '@/plugins/app/_theme/variables.css'
import '@/plugins/app/_theme/index.sass'
import '@/plugins/app/_theme/tailwind.css'


const app = createApp(App)
.use(IonicVue)
.use(router)
.use(axios, { disabled: false })
.use(ionic, { disabled: false })
.use(ionModals, { disabled: false })
.use(ionPopups, { disabled: false })
.use(ionicons, { disabled: false })

import GAuth from 'vue3-google-login'
app.use(GAuth, { clientId: process.env.VUE_APP_GAUTH_ID })

import store from '@/plugins/app/_config/store'
app.use(store)

import wLoader from '@/plugins/w/loader'
app.config.globalProperties.$wLoader = wLoader
  
router.isReady().then(() => {
  app.mount('#app');
  app.use(wBugger, { disabled: false, projectKey: 'db8bb3ee54f0e7e1b27da76a00919f273b9aad01edaf61148836247f6eb53603' })
});