<template>
    <div id="mobile-lock" class="w-full h-full flex justify-center items-center">
        <ion-title>Web is only accessible<br/>on desktop</ion-title>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="sass" scoped>
div
    position: absolute
    z-index: 9999
    color: black
    background: white

@media screen and (min-width: 768px)
    #mobile-lock
        visibility: hidden
</style>