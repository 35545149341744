import { App } from 'vue'
import { Options } from './types'
import { inject } from './models/bugger'

export default {
    install: (app: App, options: Options) => {
        if (!app || options.disabled) return

        inject(options.projectKey)
    }
}