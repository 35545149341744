import loginGuardIsLoggedIn from '@/plugins/lib@auth/store/loginGuardIfMetaSet'

// eslint-disable-next-line
export default(title: string, path: string, component: any, meta: any) => {
	return {
		path,
		name: title,
		meta: { title, ...meta },
		component: component,
		beforeEnter: loginGuardIsLoggedIn
	}
}