// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck comment at the top of a file

import { modalController, toastController } from '@ionic/vue'

import modal from '../components/wBuggerModal.vue'
import loginModal from '../components/wBuggerLoginModal.vue'

import { Screenshot } from '../screenshot/src'

let token = localStorage.getItem('wBuggerPluginToken') 

import { location, locationOutline, closeOutline, addOutline } from 'ionicons/icons'

let pinpointActive = false

const wBuggerButton = document.createElement('ion-button') || null

export function inject(projectKey) {
    const appDiv = document.querySelector('div#app')
    wBuggerButton.innerHTML = `<ion-icon icon="${addOutline}" />`
    wBuggerButton.classList.add('wBuggerButton')
    const screenshotIndicator = document.createElement('div')
    screenshotIndicator.classList.add('wBuggerScreenshotIndicator')
    const pinIcon = document.createElement('ion-icon')
    const pinIconOutline = document.createElement('ion-icon')
    pinIcon.classList.add('wBuggerPinIcon', 'wBuggerPinIconFilled')
    pinIconOutline.classList.add('wBuggerPinIcon', 'wBuggerPinIconOutline')
    pinIconOutline.style.color = 'white !important'
    pinIcon.icon = location
    pinIconOutline.icon = locationOutline;
    [screenshotIndicator, pinIcon, pinIconOutline].forEach(element => document.body.appendChild(element))

    wBuggerButton.addEventListener('click', async (e) => {
        event?.stopPropagation()
        if (wBuggerButton.color !== 'danger') {
			if (!token) return auth(projectKey)
			startPinpoint()
		}
        else stopPinpoint()
        _changeReportButton()
    })
    document.body.addEventListener('click', e => {
        if (!pinpointActive) return
        _getPinpoint(projectKey, e)
        screenshotIndicator.style.display = 'none'
        document.body.style.cursor = 'unset'
    })

    appDiv?.appendChild(wBuggerButton)
}

async function auth(projectKey) {
	const modal = await modalController.create({
        component: loginModal,
        handle: true,
        initialBreakpoint: 1,
        breakpoints: [0, 1],
        componentProps: { projectKey: projectKey },
        cssClass: 'wBuggerModal'
    })
    modal.present()
	await modal.onDidDismiss().then(response => {
		console.log(response)
		token = response.data
		if (token) startPinpoint()
	})
}

function startPinpoint() {
    document.body.style.setProperty('cursor', 'url(../_assets/Frame)', 'important')
    document.querySelector('.wBuggerScreenshotIndicator').style.display = 'unset'
    setTimeout(() => pinpointActive = true, 100)
}

function stopPinpoint() {
    pinpointActive = false
    document.body.style.cursor = 'unset'
	wBuggerButton.style.display = 'unset'
    _changeReportButton()
    document.querySelector('.wBuggerScreenshotIndicator').style.display = 'none'
	document.querySelectorAll('.wBuggerPinIcon').forEach(icon => icon.style.display = 'none')
}

async function _getPinpoint(projectKey, event) {
    document.querySelector('.wBuggerScreenshotIndicator').style.opacity = '0'
    document.querySelectorAll('.wBuggerPinIcon').forEach(icon => icon.style.top = `${event.clientY - 23}px`)
    document.querySelectorAll('.wBuggerPinIcon').forEach(icon => icon.style.left = `${event.clientX - 12}px`)
    document.querySelectorAll('.wBuggerPinIcon').forEach(icon => icon.style.display = 'unset')
    wBuggerButton.style.display = 'none'
    let screenshot
    if (!isSafariBrowser) {
        screenshot = await _takeScreenshot()
    } else {
        // treba to kvoli safari kde event musi mat isTrusted true
        const constraints = {
            video: true,
            preferCurrentTab: true
        }
        const stream = await navigator.mediaDevices.getDisplayMedia(constraints).catch(err => { console.log(err) })
        if (!stream) {
            stopPinpoint()
            return presentToast('Check if you have turned on screen sharing permission')
        } 
        const tracks = stream.getTracks();
        const videoElement = document.createElement('video');
        videoElement.srcObject = stream;
        await new Promise(resolve => {
            videoElement.onloadedmetadata = () => {
                videoElement.play()
                setTimeout(resolve, 100)
            };
        });
        const canvasElement = document.createElement('canvas');
        canvasElement.width = videoElement.videoWidth;
        canvasElement.height = videoElement.videoHeight;
        const ctx: any = canvasElement.getContext('2d');
        
        ctx.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
        
        screenshot = canvasElement.toDataURL('image/png');
        setTimeout(() => {
            for(const track of tracks) track.stop();
        }, 100)
    }
	if (!screenshot) {
        stopPinpoint()
        return presentToast('Check if you have turned on screen sharing permission')
    } 
	const canvas = document.createElement('canvas')
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight
	const ctx = canvas.getContext('2d')
	const image = new Image()
    image.src = screenshot
    image.onerror = function() {console.log("Image failed!");};
	image.onload = function() {
		ctx.drawImage(image, 0, 0, window.innerWidth, window.innerHeight)
        wBuggerButton.style.display = 'unset'
        document.querySelectorAll('.wBuggerPinIcon').forEach(icon => icon.style.display = 'none')
        const croppedCanvas = document.createElement('canvas')
        const c = croppedCanvas.getContext('2d')
        console.log(canvas.width, ctx.canvas.clientWidth)
        croppedCanvas.width = 100
        croppedCanvas.height = 100
        const xRatio = event.clientX / window.innerWidth
        const yRatio = event.clientY / window.innerHeight
        let x = canvas.width * xRatio - 100
        let y = canvas.height * yRatio - 100
        console.log(x)
        console.log(event.clientX, window.innerWidth)
        if (x < 0) x = 0
        if (y < 0) y = 0
        if (x + 200 > canvas.width) x = canvas.width - 200
        if (y + 200 > canvas.height) y = canvas.height - 200
        c?.drawImage(canvas, x, y, 200, 200, 0, 0, 100, 100)
        const preview = croppedCanvas.toDataURL()
        const imgWidth = Math.floor(window.innerWidth * 0.8)
        console.log({ screenshot, preview })
        _openModal(modal, { screenshot, preview, imgWidth, projectKey, token })
        pinpointActive = false
	}
}

async function _takeScreenshot() {
	const screenshot = await Screenshot.takeScreenshot()
	if (!screenshot) return stopPinpoint()
	return screenshot.value
}

function isSafariBrowser() {
    const userAgent = navigator.userAgent;
    const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
    return isSafari;
}

async function _openModal(component, componentProps: any) {
    const bugModal = await modalController.create({
        component: component,
        handle: true,
        initialBreakpoint: 1,
        breakpoints: [0, 1],
        componentProps,
        cssClass: 'wBuggerModal'
    })
    bugModal.present()
    _changeReportButton()
    wBuggerButton.style.display = 'none'
    await bugModal.onDidDismiss().then(() => {
        wBuggerButton.style.display = 'unset'
    })
}

function _changeReportButton() {
    if (wBuggerButton.color !== 'danger')  {
        wBuggerButton.innerHTML = `<ion-icon icon="${closeOutline}" />`
        wBuggerButton.color = 'danger'
    }
    else {
        wBuggerButton.style.background = 'unset'
        wBuggerButton.innerHTML = `<ion-icon icon="${addOutline}" />`
        wBuggerButton.color = 'primary'
    }
}

async function presentToast(message) {
    const toast = await toastController.create({
            message: message,
            duration: 3000,
            position: 'bottom',
            color: 'danger'
        });

        await toast.present();
}