// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck comment at the top of a file

import { loadingController } from '@ionic/vue'
import { ref } from 'vue'

export default {
	_isLoading: ref(false),
	loader: null,

	isLoading() {
		return this._isLoading.value
	},

	async startLoading(message = 'Loading...') {
		if (this.isLoading())
			return
		this._isLoading.value = true

		this.loader = await loadingController.create({ message })
		this.loader.present()
	},
	
	clearLoading() {
		this._isLoading.value = false
		this.loader?.dismiss()
	},

	async loadAction(action, message = 'Loading...') {
		try {
			await this.startLoading(message)

			return await action()
		} finally {
			this.clearLoading()
		}
	}
}

