import * as AllIcons from "ionicons/icons"; //All icons

const icons = new Proxy(Object.keys(AllIcons).reduce((map, elem) => {
	map[elem.toLowerCase()] = AllIcons[elem];
	return map;
}, {}), {
	get: function(target, name) {
		const icon = target[name = name.toString().toLowerCase().replace("-", "")];
		if (icon)
			return icon;

		for (const key in target)
		{
			if (key.indexOf(name) > -1)
				return target[key];
		}
	}
});

export default icons;