<template>
  <ion-app>
    <mobile-lock />
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import mobileLock from '@/plugins/app/_layout/mobile-lock.vue'
export default {
  components: {
    mobileLock
  },
}
</script>