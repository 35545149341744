import { ComponentRef } from '@ionic/core'
import { modalController, ModalOptions } from '@ionic/vue'

export { modalController } 

// export let currentlyOpened: null

export async function open(component: any, componentProps: any, config?: ModalOptions<ComponentRef>, slide = true) {
	// if (component == currentlyOpened)
	// 	return { onDidDismiss: () => ({}) }
	
	const modal = await modalController.create({
		component,
		handle: true,

		componentProps,
		...config
	})

	if (slide) {
		modal.initialBreakpoint = 1,
		modal.breakpoints = [0, 1]
	}

	// modal.onDidDismiss().then(() => currentlyOpened = null)
	
	modal.present()
	// currentlyOpened = component
	return modal
}