// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck comment at the top of a file

import axios from '@/plugins/w/axios/models/axios'
import './refreshExpired'

export default {
	namespaced: true,
    state() {
        return {
			status: '',
			_token: localStorage.getItem('wBuggerToken') || '',
			user: JSON.parse(localStorage.getItem('wBuggerUser') || '{}')
        }
    },
    mutations: {
        auth_request(state: any) {
			state.status = 'loading'
		},
		auth_success(state: any, data) {
			state.status = 'success'

			if (data.access_token) {
				state._token = data.access_token
				localStorage.setItem('wBuggerToken', data.access_token)
			}
			if (data.user) {
				state.user = data.user
				localStorage.setItem('wBuggerUser', JSON.stringify(data.user))
			}
		},
		auth_error(state: any) {
			state.status = 'error'
			state._token = ''
			localStorage.removeItem('wBuggerToken')
			localStorage.removeItem('wBuggerUser')
		},
		logOut(state: any) {
			state.status = ''
			state._token = ''
			state.user = {}
			localStorage.removeItem('wBuggerToken')
			localStorage.removeItem('wBuggerUser')
		}
    },
    actions: {
		logout({commit}: {commit: any}) {
			return new Promise((resolve, reject) => {
				axios.get_auth_data('auth/logout')
					.then(() => {
						commit('logOut')
						resolve(null)
					}).catch(() => {
						commit('logOut')
						reject()
					})
			})
		},
		async refreshToken({commit}: {commit: any}) {
			try {
				const response: any = await axios.get_auth('/auth/refresh_token')

				const token = response.access_token
				const user = response.user


				commit('auth_success', {
					access_token: token,
					user: user
				})

				return token
			} catch (error) {
				commit('auth_error')
			}
		},
		async userInfo({commit}: {commit: any}) {
			return new Promise((resolve, reject) => {
				axios.get_auth_data('v1/auth/info')
					.then((response: any) => {
						const user = response.user

						commit('auth_success', {
							user: user
						})

						resolve(user)
					}).catch(error => {
						reject(error)
					})
			})
		},
	},
	getters: {
		isLoggedIn: (state: any) =>	{
			return !!state._token
		},
		authStatus: (state: any) => {
			return state.status
		},
		user: (state: any) => {
			return state.user
		},
		token: (state: any) => {
			return state._token
		}
	}
}