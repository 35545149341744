import { createRouter, createWebHashHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import wRoute from '@/plugins/w/route'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  wRoute('home', '/home', () => import('@/plugins/lib@home/home.vue'), { requiresAuth: true }),
  wRoute('login', '/login', () => import('@/plugins/lib@auth/login.vue'), { requiresAuth: false, forbitWhenAuth: true }),
  wRoute('projectDetail' ,'/project/:key', () => import('@/plugins/lib@project-detail/project-detail.vue'), { requiresAuth: true })
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
