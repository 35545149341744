<template>
<ion-page class="modal">
		<ion-header>
			<h3>wBugger</h3>
			<ion-icon @click="close" :icon="icons.closeOutline" />
		</ion-header>
        <ion-content class="content">
			<div class="content log-in">
				<img class="oops" src="../_assets/oops.webp">
				<h2>Oops, you aren't logged in</h2>
				<input v-model="email" type="email" ref="email-input" >
				<input v-model="password" type="password" ref="password-input">
				<button @click="signIn()" class="login-button" :disabled="!email || !password">Log in</button>
			</div>
        </ion-content>
    </ion-page>
</template>

<script>
import { toastController } from '@ionic/vue'

import { modalController } from '@ionic/vue'
import { closeOutline } from 'ionicons/icons'

export default {
	props: {
		projectKey: {
			type: String
		}
	},

	data() {
		return {
			user: {},
			icons: { closeOutline },
			email: '',
			password: ''
		}
	},

	created() {
		this.token = null
	},

	methods: {
		async signIn() {
			await fetch('https://wBugger.hybridlab.dev/cms/auth/login', {
				method: 'POST',
				body: JSON.stringify({
					email: this.email,
					password: this.password,
					projectKey: this.projectKey
				}),
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(response => {
				if (!response.ok) return Promise.reject(response)
				response.json().then(json => {
					const token = json.token_type + ' ' + json.access_token
					localStorage.setItem('wBuggerPluginToken', token)
					modalController.dismiss(token)
				})
			})
			.catch(error => {
				localStorage.removeItem('wBuggerPluginToken')
				this.presentToast(error.statusText)
			})
        },
		close() {
			modalController.dismiss(this.token)
		},
		async presentToast(message) {
			const toast = await toastController.create({
				message: message,
				duration: 3000,
				position: 'bottom',
				color: 'danger'
			});

			await toast.present();
		},
	}
}
</script>

<style lang="sass" scoped>
* 
	color: black

ion-header
	display: flex
	justify-content: center
	align-items: center

	ion-icon
		position: absolute
		right: 8px
		font-size: 24px
		margin-top: 4px
		cursor: pointer

.content
	width: 100%
	height: 100%
	display: flex
	flex-direction: column
	align-items: flex-start
	--padding-top: 15px
	--padding-bottom: 10px
	--padding-start: 5%
	--padding-end: 5%

.log-in
	display: flex
	flex-direction: column
	justify-content: space-around
	align-items: center
	
	img.oops
		height: 50%
		aspect-ratio: 1/1s
		margin-bottom: -10%

</style>