<template>
    <ion-page class="modal">
		<ion-header>
			<h3>wBugger</h3>
		</ion-header>
        <ion-content class="content">
			<div class="content">
				<textarea ref="textarea" v-model="description" auto-grow placeholder="Describe the bug.." />
				<ion-select v-model="severity" interface="popover">
					<ion-select-option class="breaking" value="breaking">High</ion-select-option>
					<ion-select-option class="normal" value="normal">Medium</ion-select-option>
					<ion-select-option class="nice-to-have" value="niceToHave">Nice to have</ion-select-option>
				</ion-select>
				<img @click="openScreenshot()" class="screenshot-preview" :src="preview" alt="screenshot failed">
			</div>
        </ion-content>
		<ion-footer>
			<ion-button @click="close()">Cancel</ion-button>
			<ion-button @click="send()">Send</ion-button>
		</ion-footer>
    </ion-page>
</template>

<script>
import wBuggerImageModal from './wBuggerImageView.vue'

import axios from 'axios'

import { modalController } from '@ionic/vue'
import { closeOutline } from 'ionicons/icons'

const api = {
    login: async (code, social) => axios.post_auth_data('/auth/login', { code, social }),
	send: async (projectKey, data) => axios.post(`https://wbugger.hybridlab.dev/cms/projects/${projectKey}/tickets`, data)
}

export default {
    props: {
		screenshot: {
			required: true,
		},
		preview: {
			required: true
		},
		imgWidth: {
			type: Number
		},
		projectKey: {
			type: String
		},
		token: {
			type: String
		}
	},

	data() {
		return {
			description: '',
			severity: 'normal',
			icons: { closeOutline }
		}
	},

	methods: {
		async openScreenshot() {
			const modal = await modalController.create({
				component: wBuggerImageModal,
				componentProps: { screenshot: this.screenshot, imgWidth: this.imgWidth },
				cssClass: 'wBuggerImageModal',
			})
			modal.present()
		},
		async send() {
			const data = { screenshot: this.screenshot, description: this.description, priority: this.severity, preview: this.preview, bug_url: window.location.href }
			await fetch(`https://wBugger.hybridlab.dev/cms/projects/${this.projectKey}/tickets`, {
				method: 'POST',
				headers: {
					Authorization: this.token,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data)
			})
			this.close()
		},
		close() {
			modalController.dismiss()
		},
	}
}
</script>

<style lang="sass" scoped>
* 
	color: black

ion-header
	display: flex
	justify-content: center
	align-items: center

	ion-icon
		position: absolute
		right: 8px
		font-size: 24px
		margin-top: 4px
		cursor: pointer

.content
	width: 100%
	height: 100%
	display: flex
	flex-direction: column
	align-items: flex-start
	--padding-top: 15px
	--padding-bottom: 10px
	--padding-start: 5%
	--padding-end: 5%

	textarea
		width: 100%
		height: 70%
		background: white
		color: black
		border: 2px solid gray
		border-radius: 5px
		display: inline-block
		margin-bottom: 5px
		overflow-y: hidden
		resize: none

	ion-select
		height: 45px
		width: 100%
		border: 2px solid gray
		border-radius: 5px
		margin-bottom: 5px

		.breaking
			background: orange
		.normal
			background: yellow
		.nice-to-have
			background: blue

	.screenshot-preview
		width: 100px
		height: 100px
		cursor: pointer
		border: 2px solid black
		border-radius: 5px

ion-footer
	display: flex
	justify-content: flex-end

	ion-button
		color: white
</style>

<style lang="sass">
.wBuggerModal
	--height: 80vh
	
	&::part(content)
		z-index: 1001
		position: absolute
		bottom: 0
		margin: auto
		border-radius: 5px 5px 0 0

@media only screen and (min-width: 768px) and (min-height: 600px) 
	.wBuggerModal
		--width: 60vw
		--height: 75vh
		--border-radius: 3px

		&::part(content)
			border-radius: 5px
			top: 0
			right: 0
			left: 0

.wBuggerButton
	z-index: 1000
	position: absolute
	bottom: 3px
	right: 3px
	height: 50px
	width: 50px
	display: flex
	justify-content: center
	align-items: center
	--border-radius: 25px

.wBuggerScreenshotIndicator
	border: 2px solid green
	width: 100vw
	height: 100vh
	box-sizin: border-box
	display: none 
	z-index: 999
	position: absolute
	cursor: url(../_assets/cursor.png) 8 15, crosshair

.wBuggerPinIcon
	font-size: 25px
	display: none
	position: absolute

.wBuggerPinIconOutline
	color: black
.wBuggerPinIconFilled
	color: red

.wBuggerImageModal
	--backdrop-opacity: 0.4 !important
	&::part(content)
		z-index: 1002
</style>