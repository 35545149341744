<template>
    <div class="screenshotView">
        <ion-icon @click="close" :icon="closeOutline" class="close-icon" />
        <img :src="screenshot" :style="`width: ${imgWidth}px`" alt="si kokot..">
    </div>
</template>

<script>
import { closeOutline } from 'ionicons/icons'
import { modalController } from '@ionic/vue'

export default {
    props: {
        screenshot: {
            required: true
        },
        imgWidth: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            closeOutline
        }
	},

    methods: {
        close() {
            modalController.dismiss()
        }
    }
}
</script>

<style lang="sass" scoped>
.screenshotView
    position: relative
    
    img
        margin-bottom: -6px

    .close-icon
        position: absolute
        top: 5px
        right: 5px
        cursor: pointer
        color: black
        font-size: 30px

</style>

<style lang="sass">
.wBuggerImageModal
    --height: max-content
    --width: max-content
    
    &::part(content)
        border-radius: 5px
</style>