import { App } from 'vue'
import { alert, confirm, prompt, currentlyOpened, alertController } from './models/popups'
import { Options } from './types'

export default {
	install: (app: App, options: Options) => {
		if (options?.disabled) return

		document.addEventListener("keyup", ev => {
			if (ev.code == "Enter" && currentlyOpened)
				document.getElementById("confirm-button")?.click()
		})
		app.config.globalProperties.$popups = { alert, confirm, prompt, currentlyOpened, ...alertController }
	}
}