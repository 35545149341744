import { App } from 'vue'
import icons from './models/icons'
import { Options } 	from './types'

export default {
	install: (app: App, options: Options) => {
		if (options?.disabled) return

		app.config.globalProperties.$icons = icons
	}
}